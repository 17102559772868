.activity-grid {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-gap: 32px;
    width: -moz-available;
    width: -webkit-fill-available;
    padding: 0;
    margin: 16px 0 0 0;

    @media (min-width: 600px) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    @media (min-width: 1000px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media (min-width: 1440px) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .chart {
        @media (min-width: 600px) {
            grid-row: 1 / 2;
            grid-column: 1 / -1;
        }

        @media (min-width: 1000px) {
            grid-row: 1 / 3;
            grid-column: 1 / -1;
        }

        @media (min-width: 1440px) {
            grid-row: 1 / 3;
            grid-column: 1 / 3;
        }
    }

    .highlights {
        @media (min-width: 600px) {
            grid-row: 2 / 3;
            grid-column: 1 / -1;
        }

        @media (min-width: 1000px) {
            grid-row: 3 / 4;
            grid-column: 1 / 2;
        }

        @media (min-width: 1440px) {
            grid-row: 1 / 2;
            grid-column: 3 / 4;
        }
    }

    .location {
        @media (min-width: 600px) {
            grid-row: 3 / 4;
            grid-column: 1 / -1;
        }

        @media (min-width: 1000px) {
            grid-row: 3 / 5;
            grid-column: 2 / 3;
        }

        @media (min-width: 1440px) {
            grid-column: 3 / 4;
            grid-row: 2 / 4;
        }
    }

    .photos {
        @media (min-width: 600px) {
            grid-row: 4 / 5;
            grid-column: 1 / -1;
        }

        @media (min-width: 1000px) {
            grid-row: 5 / 6;
            grid-column: 2 / 3;
        }

        @media (min-width: 1440px) {
            grid-row: 3 / 4;
            grid-column: 2 / 3;
        }
    }

    .details {
        @media (min-width: 600px) {
            grid-row: 5 / 7;
            grid-column: 1 / -1;
        }

        @media (min-width: 1000px) {
            grid-row: 4 / 6;
            grid-column: 1 / 2;
        }

        @media (min-width: 1440px) {
            grid-column: 1 / 2;
            grid-row: 3 / 5;
        }
    }

    .notes {
        @media (min-width: 600px) {
            grid-row: 7 / 9;
            grid-column: 1 / -1;
        }

        @media (min-width: 1000px) {
            grid-row: 6 / 7;
            grid-column: 1 / -1;
        }

        @media (min-width: 1440px) {
            grid-row: 4 / 5;
            grid-column: 2 / 4;
        }
    }

    .debug {
        grid-column: 1 / -1;

        .details-mobile-row {
            margin-left: 16px;
            margin-right: 16px;
            padding: 12px 0 14px;
            border-bottom: solid 1px $smartester-light;

            p {
                margin-bottom: 0;
            }
        }
    }
}

.zoom-control {
    border: 1px solid $smartester-light !important;
    background-color: #FFF;
    z-index: 100;
    position: absolute;
    width: 40px;
    border-radius: 8px;
    bottom: 48px;
    right: 32px;
    padding: 0px 4px;
}

.zoom-in {
    border-bottom: 1px solid $smartester-light !important;
}

.zoom-in, .zoom-out {
    font-family: "Montserrat-Medium" !important;
    font-size: 24px;
    color: #707070;
    cursor: pointer;
    margin: 0;
    text-align: center;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.pan {
    opacity: 0;
    font-size: 120px !important;
    position: absolute;
    color: $smartester-secondary;
    cursor: pointer;
    z-index: 100;
    transition: opacity 2s;
}

.pan:hover {
    opacity: 1;
}

.pan.right {
    right: 24px;
    top: 0;
    bottom: 24px;
    margin: auto 0;
}

.pan.left {
    left: 48px;
    top: 0;
    bottom: 24px;
    margin: auto 0;
}

.pan.up {
    top: 32px;
    left: 0;
    right: 0;
    margin: -25px auto 0;
}

.pan.down {
    bottom: 16px;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.chart-card {
    height: 280px;
    position: relative;

    .MuiButton-outlinedSecondary {
        border: 2px solid $smartester-light !important;

        &:hover {
            border: 2px solid $smartester-secondary !important;
            color: $smartester-secondary !important;
        }
    }

    .MuiButton-outlinedSecondary.active {
        border: 2px solid $smartester-secondary !important;
    }

    @media (min-width: 820px) {
        height: 360px !important;
    }

    @media (min-width: 1080px) {
        height: 520px !important;
    }

    @media (min-width: 1440px) {
        height: 618px !important;
    }

    @media (min-width: 904px) {
        .VictoryContainer {
            height: calc(100% - 42px) !important;
        }
    }
}

.chart-menu {
    z-index: 10;
    margin-left: auto !important;
    border-radius: 8px !important;
    padding: 8px 10px !important;
}

.chart-analysis {
    position: absolute;
    border-radius: 8px;
    border: 2px solid $smartester-light !important;
    right: 73px;
    top: 51px;
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 8px 16px 0px;
    z-index: 100;
}

.chart-header {
    display: flex;
    flex-direction: row;
    margin-top: 16px;
    margin-right: 20px;
}

.chart-buttons {
    margin-left: 20px;
}

.chart-action {
    border-width: 1px !important;
    padding: 6px 16px !important;
    border-radius: 8px !important;
}

.activity-grid.expanded {
    .chart {
        grid-column: 1 / -1 !important;
    }

    .chart-card {
        height: auto !important;
    }
}

.empty-notes {
    height: 120px !important;
    width: 120px !important;
    padding: 20px !important;
    background-color: $smartester-light;
    color: $smartester-neutral-light !important;
    border-radius: 50%;
    margin-bottom: 24px !important;
}

.notes-body {
    display: flex;
    justify-content: space-between;

    .MuiOutlinedInput-multiline {
        height: -webkit-fill-available !important;
        height: -moz-available !important;

        .MuiOutlinedInput-inputMultiline {
            margin-bottom: auto;
        }
    }

    height: calc(100% - 24px);

    textarea[id="dispute-notes"],
    textarea[id="installer-notes"] {
        @media (min-width: 905px) {
            height: 100% !important;
        }

        overflow: unset !important;
    }

    @media (max-width: 905px) {
        flex-direction: column;
    }
}

.notes-fieldset {
    border: 1px rgba(141, 141, 141, 0.5) solid;
    border-radius: 4px;
    display: inline-block;
    height: 100%;
    padding-bottom: 10px;
}

.notes-box-left {
    margin-bottom: 20px !important;
    width: 100%;
    height: 195px;
    padding-right: 8px !important;

    @media (max-width: 905px) {
        height: unset;
        padding-right: unset !important;
    }
}

.notes-legend {
    width: auto;
    font-size: 12px;
    margin-left: 10px;
    color: rgba(141, 141, 141, 0.5);
    padding: 0 5px;
    text-align: left !important;
}

.notes-text {
    margin-left: 15px;
    margin-right: 25px;
    line-height: 20px;
    color: rgba(0,0,0,1);
    overflow: auto;
    height: 100%;
    text-align: left !important;
}

.notes-card {
    padding: 12px !important;

    @media (max-width: 905px) {
        height: auto;
    }

    @media (min-width: 1440px) {
        height: 485px !important;
    }

    .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
        border-color: rgba(0, 0, 0, 0.23) !important;
    }

    .Mui-focused {
        fieldset {
            border-color: rgba(0, 0, 0, 0.23) !important;
        }
    }

    .MuiFormLabel-root.Mui-focused {
        color: rgba(0, 0, 0, 0.54) !important;
    }
}

.notes-label,
.no-photos-label {
    overflow: hidden;
    text-overflow: ellipsis;
}

.empty-photos-box {
    text-align: center;
}

.notes-box {
    width: 50%;
    text-align: center;

    @media (max-width: 905px) {
        width: auto;
    }
}

.notes-container {
    text-align: center;
    margin: 0px auto;
}

.highlights {
    &__link {
        color: unset;

        &:last-of-type {
            background-color: red;

            .highlights-item {
                border-bottom: none;
            }
        }
    }
}


.highlights-card {
    height: 410px;
    position: relative;
    overflow: hidden;
    border-radius: 6px;
}

.highlights-item {
    display: flex;
    align-items: center;
    padding: 8px 25px;
    border-bottom: solid 1px $smartester-light;

    &__wrapper {
        position: absolute;
        width: 100%;
        overflow: auto;
        height: 80%;
    }

    &__current {
        background-color: rgb(236, 236, 236);
    }

    &:last-of-type {
        // border-bottom: none;
    }
}

.highlights-comparing {
    .highlights-item {
        padding: 8px;

        &__current {
            background-color: #FEE3E5;
            cursor: default;

            .highlights-text {
                color: $smartester-primary;
            }
        }

        &__secondary {
            background-color: #EFE8F4;

            .highlights-text {
                color: #9368B7;
            }
        }

        &__disabled {
            background-color: $smartester-lightest;
            cursor: default;
        }
    }
}

.highlights-img {
    margin-right: 16px;
    margin-top: auto;
    margin-bottom: auto;
}

.highlights-text {
    font-size: 16px !important;
    margin-bottom: 0;
}

.highlights-result {
    border-radius: 16px;
    padding: 0 16px;
    color: $color-white;
    text-transform: uppercase;
    background-color: $smartester-primary;
    font-size: 19px;
    font-family: "Montserrat-Regular" !important;
    margin-bottom: 0;
    margin-left: auto;
    min-height: 32px;
    line-height: 32px;
}

.highlights-result.pass {
    background-color: $smartester-success;
}

.highlights-result.aborted {
    background-color: $smartester-light;
    color: $smartester-dark;
}

.details-row {
    display: flex;
    justify-content: space-between;
    margin-left: 16px;
    padding: 14px 16px 13px 0;
    border-bottom: solid 1px $smartester-light;

    p {
        margin-bottom: 0;
    }
}

.details-row.final {
    border-bottom: unset !important;
    margin-bottom: 9px;
}

.details-mobile-row {
    margin-left: 16px;
    padding: 12px 0 14px;
    border-bottom: solid 1px $smartester-light;

    p {
        margin-bottom: 0;
    }
}

.details-mobile-row.final {
    border-bottom: unset !important;
    margin-bottom: 9px;
}

.photos-placeholder {
    background-color: $smartester-light;
    border-radius: 4px;
    width: 144px;
    height: 144px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin-right: 10px;
    margin-left: 10px;
}

button.photo-preview {
    background-size: cover;
    background-position: center;
    cursor: pointer;
    border: none !important;
    border-width: 0 !important;
}

.photo-dot {
    height: 8px;
    width: 8px;
    background-color: $smartester-primary;
    border-radius: 50%;
    display: inline-block;
    margin: 6px;
    opacity: 0.4;
}

.photo-dot.active {
    opacity: 1 !important;
}

.location-card {
    height: 531px;

    @media (min-width: 905px) and (max-width: 1439px) {
        height: 785px !important;
    }
}

.no-location-icon {
    height: 120px;
    width: 120px;
    padding: 20px;
    border-radius: 60px;
    background-color: $smartester-light;
    margin-bottom: 24px;
}

.location-img {
    height: 432px;

    @media (min-width: 905px) and (max-width: 1439px) {
        height: 606px !important;
    }

    // max-width: 640px;
    border: solid 1px $smartester-neutral-dark;
}

.activity-photo-count {
    position: absolute;
    top: -8px;
    right: -6px;
    font-size: 12px;
    background: #363636;
    border-radius: 50%;
    color: white;
    width: 20px;
    height: 20px;
    margin: 0;
    text-align: center;
    line-height: 20px;
}

button.MuiButton-root {
    min-width: 0 !important;
    min-height: 0 !important;
    text-transform: none !important;
}

.activity-page {
    .MuiButton-startIcon {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
}

.test-navigator {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #E0E0E0;
    padding: 13px 16px;
    align-items: center;

    &__wrapper {
        position: sticky;
        top: 0;
    }
}

button.test-navigator {
    &__button {
        border-radius: 0;
        margin: 0;
        border: 1px solid #E0E0E0;
        padding: 6px 8px;

        &:first-of-type {
            border-right: none;
            border-radius: 6px 0 0 6px;
        }

        &:last-of-type {
            border-radius: 0 6px 6px 0;
        }
    }
}

button.test-navigator:first-of-type {
    &__button {
        background-color: red;
        border-radius: 0;
        margin: 0;
        border-left: none;
    }
}