.header {
    top: 0;
    position: fixed;
    height: 64px;
    margin-left: 256px;
    z-index: 100;
    overflow: hidden;
    width: -moz-available;
    width: -webkit-fill-available;
    justify-content: space-between;
    box-shadow: 0px 3px 6px #00000029;
    transition: margin 300ms;
    background-color: #FFF;
    padding: 0;
}

.profile-button {
    display: flex;
    justify-content: space-between;
}

.profile-container {
    height: 64px;
    max-width: 386px;
    cursor: pointer;
}

.profile-mobile-container {
    height: 64px;
    width: 72px;
    cursor: pointer;
}

.user-image {
    height: 44px;
    width: 58px;
    margin: 10px 0 10px 10px;
    padding-left: 14px;
    border-left: 1px solid $smartester-light;

    img {
        height: 44px;
        width: 44px;
        border-radius: 50%;
        object-fit: cover;
        border: 1px solid $smartester-light;
    }
}

.user-mobile-image {
    height: 44px;
    width: 58px;
    margin: 10px 14px 10px 0px;
    padding-left: 14px;
    border-left: 1px solid $smartester-light;

    img {
        height: 44px;
        width: 44px;
        border-radius: 50%;
        object-fit: cover;
        border: 1px solid $smartester-light;
    }
}

.user-icon {
    height: 44px !important;
    width: 58px !important;
    margin: 10px 0 10px 0px;
    padding-left: 12px;
    border-left: 1px solid $smartester-light;
}

.user-mobile-icon {
    height: 44px !important;
    width: 58px !important;
    margin: 10px 16px 10px 0px;
    padding-left: 12px;
    border-left: 1px solid $smartester-light;
}

.user-name {
    font-size: 14px;
    font-family: 'Montserrat-Regular';
    color: #000;
    margin: auto 16px auto 8px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.profile-menu-icon {
    color: $smartester-secondary !important;
    display: inline-flex !important;
    min-width: unset !important;
    margin-right: 4px !important;
    flex-shrink: 0 !important;
}

.profile-link {
    font-size: 14px;
    font-family: 'Montserrat-Regular';
    color: #000;
    margin-bottom: 0 !important;
}

.profile-link:hover {
    text-decoration: none;
    color: #000;
}

div[id="customised-profile-menu"] > div:first-of-type {
    background-color: rgb(0,0,0,0.3) !important;
    -webkit-tap-highlight-color: rgb(0,0,0,0.3) !important;
}

div[id="customised-profile-menu"] {
    .MuiPaper-root {
        top: 64px !important;
        left: unset !important;
        right: 0 !important;
        border-radius: 4px !important;
        box-shadow: 0px 5px 5px #00000033;
        border: unset !important;
        padding: 8px 0 !important;
        margin-top: 6px !important;
        margin-right: 20px !important;
    }

    .MuiList-root {
        padding: 0 !important;
    }

    .MuiListItem-button:hover {
        background-color: $smartester-lightest !important;
    }
}

.profile-menu-li {
    border-bottom: 1px solid $smartester-light !important;    
}

.profile-menu-li:hover {
    background-color: $smartester-lightest !important;
}

.navigation-icon {
    margin-left: 20px !important;
    font-size: 24px !important;
    cursor: pointer;
}

.MuiListItem-root.Mui-focusVisible {
    background-color: #FFF !important
}

.MuiListItem-root.Mui-focusVisible:hover {
    background-color: $smartester-lightest !important
}
