.rows-per-page-label {
    margin: 0;
}

.smartesters-table {
    width: -webkit-fill-available;
    width: -moz-available;
    max-width: 1000px;
}

.MuiTablePagination-caption {
    color: $smartester-dark !important;
}

.MuiTableBody-root {
    .MuiTableRow-root.expandable {
        cursor: pointer !important;
    }
    .MuiTableRow-root {
        background-color: #fff !important;
    }

    .MuiTableRow-root:hover {
        background-color: $smartester-lightest !important;
    }
}

.MuiTablePagination-select {
    padding-left: 12px !important;
    padding-right: 0 !important;
    padding-top: 11px !important;
    padding-bottom: 10px !important;
    min-height: unset !important;
    text-align: left !important;
    text-align-last: left !important;
    font-size: 16px !important;
}

.MuiTablePagination-input {
    width: 86px !important;
    margin-right: 20px !important;
    border: 1px solid $smartester-neutral-light;
    border-radius: 4px;
}

.MuiTablePagination-input.Mui-focused {
    border-color: $smartester-primary !important;
    svg.MuiSelect-icon {
        color: $smartester-primary !important;
    }
}

.MuiMenuItem-root.Mui-selected, .MuiMenuItem-root.Mui-selected:hover {
    background-color: rgba(0, 0, 0, 0.08) !important;
}

.MuiTablePagination-select:focus {
    background-color: unset !important;
}

.MuiTablePagination-selectIcon {
    margin-right: 12px !important;
}

.MuiTablePagination-menuItem {
    font-size: 14px !important;
    font-family: "Montserrat-Regular" !important;
    color: #000 !important;
}

.MuiTablePagination-toolbar {
    p {
        color: $smartester-dark !important;
        font-size: 14px !important;
        font-family: "Montserrat-Regular" !important;
        margin: unset !important;
    }
}

.MuiList-root {
    padding: 0 !important;
}

.MuiTablePagination-menuItem {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
}

.MuiMenu-paper {
    margin-top: 2px !important;
}

.table-header {
    height: 64px;
    background-color: $smartester-secondary;
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    border-radius: 8px 8px 0 0;
}

.table-search-icon {
    margin: 3px 8px 4px 4px;
    color: $smartester-light;
}

.table-search-clear-icon {
    color: $smartester-light;
    margin: 3px 8px 2px 4px;
    float: left;
    cursor: pointer;
}

.table-enter-button {
    color: $smartester-darkest;
    margin: 4px 4px 4px 4px;
    cursor: pointer;
}

.table-header-actions-icon {
    color: #e0e0e0;
    padding: 12px;
}

.table-header-actions {
    margin: auto 4px;
    display: flex;
}

.table-header-actions-button:hover {
    background-color: $smartester-darkest !important;
}

.table-header-actions-date:hover {
    background-color: $smartester-dark !important;
}

.table-header-actions-date {
    font-family: "Montserrat-Regular";
    font-size: 16px;
    color: $smartester-light !important;
    cursor: pointer;
    border-radius: 12px;
    padding: 0 6px;
    margin: auto 14px auto 4px;
}

.search-enter-button-div {
    float: right;
    background-color: $smartester-light;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    height: 31px;
    width: 32px;
    overflow: hidden;
}

.table-search-box {
    margin: 16px 0 16px 20px;
    width: 400px;
    border-radius: 4px;
    padding: 1px;

    .MuiFormControl-root {
        width: calc(100% - 72px);
    }
    .MuiInput-underline:before {
        content: none;
    }
    .MuiInput-underline:after {
        content: none;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill::first-line,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    webkit-autofill:focus {
        -webkit-text-fill-color: #fff;
        -webkit-box-shadow: 0 0 0px 1000px $smartester-secondary inset;
        font-size: 16px;
        font-family: "Montserrat-Regular";
    }
}

.table-search-box.active {
    padding: 0;
    border: solid 1px $smartester-light;
}

input[id="table-search-input"] {
    font-size: 16px !important;
    color: $smartester-neutral-light !important;
    margin: 0;
}

.filled {
    input[id="table-search-input"] {
        color: #fff !important;
        padding: 4px 0 0 0 !important;
    }
}

.table-head {
    height: 56px;
    background-color: $smartester-light;

    .MuiTableCell-head {
        font-family: "Montserrat-SemiBold";
        font-size: 16px;
        color: $smartester-darkest;
    }
}

.MuiTablePagination-root {
    margin-top: 8px;
    overflow: unset !important;
}

.MuiTableCell-root {
    padding: 0px 0px 0px 32px !important;
    height: 52px !important;
    position: relative;

    .calibration-hazard {
        position: absolute;
        left: 130px;
        margin-left: unset;
        stroke-width: 0;
    }
}

.MuiTableRow-root:last-child {
    .MuiTableCell-root {
        border-bottom: unset;
    }
}

.MuiTableCell-body {
    color: #000 !important;
    font-family: "Montserrat-Regular" !important;
    font-size: 16px !important;
}

.table-header-sort-button {
    color: $smartester-darkest !important;
    padding: 0 !important;
    width: 24px;
    height: 24px;
}

.table-header-sort-button:hover {
    padding: 0 !important;
}

.sort-button-group {
    margin-left: 4px !important;
}

.double-sort-arrow {
    opacity: 1;
    padding: 1.5px;
    height: 24px;
    width: 24px;
}

.MuiTableRow-root.expanded-child:hover {
    background-color: unset !important;
}

.MuiTableRow-root.expanded-row {
    background-color: #364eff33 !important;
}

.MuiTableRow-root.expanded-row:hover {
    background-color: #364eff33 !important;
}

.expanded-cell {
    padding: 0;
    .MuiTableRow-root:hover {
        background-color: unset !important;
    }
    .MuiTableRow-root.bg-lightest:hover {
        background-color: $smartester-lightest !important;
    }
    .MuiTableRow-root.bg-lightest {
        background-color: $smartester-lightest !important;
    }
    .MuiTableBody-root {
        .MuiTableRow-root {
            background-color: #fafafa !important;
            cursor: pointer;
        }
        .MuiTableRow-root:hover {
            background-color: $smartester-lightest !important;
        }
    }
}

.job-activity-counter {
    background-color: $smartester-secondary !important;
    width: 32px;
    height: 32px;
    color: #fff !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
}

.status-pill {
    padding: 3px 6px;
    border-radius: 12px;
    color: #fff;
    background-color: $smartester-primary;
    display: inline;
}

.status-pill.pass, .status-pill.active {
    background-color: $smartester-success !important;
}

.status-pill.aborted, .status-pill.dormant {
    background-color: $smartester-light !important;
    color: $smartester-dark !important;
}

.pdf-icon {
    padding-left: 16px !important;
}

.expand-cevron-icon {
    padding-left: 0px !important;
}

.search-icon-button:hover {
    background-color: #f9f9f9 !important;
}

.expanded-icon {
    margin: auto 8px auto 0;
}
