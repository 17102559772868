@import "variables";

$theme-colors: (
    "secondary": $smartester-secondary,
    "primary": $smartester-primary,
    "info": $smartester-light,
);

$grid-breakpoints: (
    xs: 0,
    sm: 600px,
    md: 905px,
    lg: 1240px,
    xl: 1440px,
);

@import "~bootstrap/scss/bootstrap";
@import "skeleton";

@font-face {
    font-family: "Montserrat-Regular";
    src: local("Montserrat-Regular"),
        url("../resources/fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Montserrat-Medium";
    src: local("Montserrat-Medium"),
        url("../resources/fonts/Montserrat-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "Montserrat-SemiBold";
    src: local("Montserrat-SemiBold"),
        url("../resources/fonts/Montserrat-SemiBold.ttf") format("truetype");
}

@font-face {
    font-family: "Montserrat-Bold";
    src: local("Montserrat-Bold"),
        url("../resources/fonts/Montserrat-Bold.ttf") format("truetype");
}

div[id="root"] {
    position: relative;

    background-color: #f9f9f9;

    overflow: overlay;
    font-family: "Montserrat-Regular" !important;

    .content {
        margin-top: 4rem;
        flex: 1;
        margin-left: 256px;
        padding: 0;
        transition: margin 300ms;

        ::-webkit-scrollbar {
            margin-top: 0 !important;
        }

        ::-webkit-scrollbar-track {
            margin-top: 0 !important;
        }

        ::-webkit-scrollbar-thumb {
            margin-top: 0 !important;
        }

        ::-webkit-scrollbar-thumb:hover {
            margin-top: 0 !important;
        }
    }

    h1 {
        font-size: 44px;
        font-family: "Montserrat-SemiBold" !important;
    }

    h2 {
        font-size: 30px;
        font-family: "Montserrat-SemiBold" !important;
    }

    h3 {
        font-size: 20px;
        font-family: "Montserrat-Bold" !important;
    }

    h4 {
        font-size: 20px;
        font-family: "Montserrat-SemiBold" !important;
    }

    h5 {
        font-size: 20px;
        font-family: "Montserrat-Regular" !important;
    }

    h6 {
        font-size: 18px;
        font-family: "Montserrat-Regular" !important;
    }

    b {
        font-family: "Montserrat-SemiBold" !important;
        color: $smartester-dark !important;
    }

    .subtitle-1 {
        font-size: 16px;
        font-family: "Montserrat-SemiBold" !important;
    }

    .subtitle-2 {
        font-size: 16px;
        font-family: "Montserrat-Medium" !important;
    }

    .body-1 {
        font-size: 16px;
        font-family: "Montserrat-Regular" !important;
    }

    .body-2 {
        font-size: 14px;
        font-family: "Montserrat-Regular" !important;
    }

    .overline {
        font-size: 10px;
        font-family: "Montserrat-Regular" !important;
    }

    .caption {
        font-size: 12px;
        font-family: "Montserrat-Regular" !important;
    }

    .MuiTypography-body2 {
        font-size: 14px;
        font-family: "MontSerrat-Regular" !important;
    }

    .no-nav {
        transition: margin 300ms;
        margin-left: 0;
    }

    .bold {
        font-family: "Montserrat-SemiBold" !important;
        font-weight: bolder;
    }
}

a:hover {
    color: inherit;
}

.card-header {
    padding: 43px 17px 0 17px;
    margin-bottom: 0;
    background-color: #f9f9f9;
    border: 0;
}

.card-body {
    padding: 0 17px 44px;
}

.align-right {
    text-align: right;
}

.full-width {
    width: -moz-available;
    width: -webkit-fill-available;
}

.MuiInputLabel-outlined,
.MuiInputBase-input,
.MuiOutlinedInput-input,
.MuiTypography-body1 {
    font-family: "Montserrat-Regular" !important;
}

.MuiButton-root {
    font-family: "Montserrat-Medium" !important;
    text-transform: none !important;
    font-size: 20px !important;
}

.button-2 {
    font-size: 14px;
}

.MuiButton-startIcon {
    .MuiCircularProgress-root {
        width: 1.5rem !important;
        height: 1.5rem !important;
    }
}

.MuiButton-root {
    min-height: 52px !important;
    min-width: 96px !important;
}

.MuiButton-outlined {
    border-width: 2px !important;
}

.MuiButton-outlinedSecondary {
    border: 2px solid $smartester-secondary !important;
    color: $smartester-secondary !important;
}

.MuiButton-outlinedSecondary:hover {
    background: unset !important;
    color: $smartester-dark !important;
    border: 2px solid $smartester-dark !important;
}

.MuiButton-contained.Mui-disabled {
    color: rgba(0, 0, 0, 0.26) !important;
    background-color: hsla(0, 0%, 60%, .102) !important;
    border: unset !important;
}

.MuiButton-containedSecondary {
    border: 1px solid $smartester-neutral-dark !important;
    box-shadow: unset !important;
    color: #fff !important;
    background-color: $smartester-secondary !important;
}

.MuiButton-containedSecondary:hover {
    background: $smartester-secondary-light !important;
}

.MuiButton-containedSecondary.Mui-disabled {
    color: rgba(0, 0, 0, 0.26) !important;
    border: 0 !important;
}

.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: #cc353f !important;
}

.MuiButton-textPrimary:hover {
    background-color: unset !important;

    color: #ff8790 !important;
}

.MuiButton-textPrimary {
    .MuiTouchRipple-child {
        background-color: $smartester-light !important;
    }
}

.MuiButton-containedPrimary {
    border: 1px solid #ff8790 !important;
    color: #fff !important;
    background-color: $smartester-primary !important;
}

.MuiButton-containedPrimary:hover {
    background: #ff6570 !important;
}

.MuiSelect-select:focus {
    background: unset !important;
    border-radius: unset !important;
}

.secondary-hover:hover {
    background: $smartester-secondary-light !important;
}

.checkbox {
    .MuiTypography-root {
        font-size: 14px;
    }
}

.MuiPaper-elevation8 {
    min-width: unset !important;
    li {
        font-family: "Montserrat-Regular" !important;
        font-size: 14px !important;
        padding: 16px !important;
    }
}

.color-neutral-dark {
    color: $smartester-neutral-dark !important;
}

.color-primary {
    color: $smartester-primary !important;
}

.color-darkest {
    color: $smartester-darkest !important;
}

.color-dark {
    color: $smartester-dark !important;
}

.color-light {
    color: $smartester-light !important;
}

.color-neutral-light {
    color: $smartester-neutral-light !important;
}

.color-error {
    color: $smartester-error !important;
}

.bg-lightest {
    background-color: $smartester-lightest !important;
}

::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-track {
    background: none;
    border-radius: 2px;
}

::-webkit-scrollbar-thumb {
    background: $smartester-secondary-lightest;
    border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
    background: $smartester-secondary;
}

.content {
    ::-webkit-scrollbar {
        margin-top: 64px;
    }

    ::-webkit-scrollbar-track {
        margin-top: 64px;
    }

    ::-webkit-scrollbar-thumb {
        margin-top: 64px;
    }

    ::-webkit-scrollbar-thumb:hover {
        margin-top: 64px;
    }
}

.no-margin {
    margin: 0 !important;    
}

.color-black {
    color: #000 !important;
}

.breadcrumbs {
    display: flex;
    margin-left: 8px;
    margin-top: 26px;
    margin-bottom: 16px;
    flex-wrap: wrap;
    width: -moz-available;
    width: -webkit-fill-available;
}

.breadcrumb-item {
    color: $smartester-neutral-light;
    font-family: "Montserrat-Medium";
    font-size: 14px;
    margin-left: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.breadcrumb-item + .breadcrumb-item::before {
    margin-right: 8px;
    padding-right: 0;
    color: $smartester-neutral-light;
}

.breadcrumb-item {
    padding-left: 0 !important;
}

.breadcrumb-item.link {
    color: $smartester-support-1 !important;
    cursor: pointer;
}

.breadcrumb-item.link:hover {
    text-decoration: underline;
}

.breadcrumb-loading {
    height: 21px;
    margin-left: 13px;
    width: 100px !important;
    @include background-gradient;
    animation: shine-lines $animation-duration infinite linear;
    border-radius: 5px;
}

.breadcrumb-item:first-child {
    .breadcrumb-loading {
        margin-left: 0;
    }
}

.resend-invite-btn {
    min-width: 180px !important;
}

.card-title {
    margin-bottom: 0;
}

.no-margin-bottom {
    margin-bottom: 0;
}

.no-decoration-link {
    text-decoration: unset;
    color: #757575;

    &:hover {
        text-decoration: underline;
    }
}

.custom-snackbar {
    background-color: $smartester-secondary;
    min-width: 432px;
    min-height: 64px;
    color: #fff;
    border-radius: 8px;
    display: flex;
    width: fit-content;
    justify-content: space-between;
    margin: 32px 5vw;

    .MuiIconButton-root {
        margin: auto 8px auto 0;
        height: 40px;
        width: 40px;
    }

    .MuiIconButton-root:hover {
        background-color: $smartester-darkest !important;
    }
}

.custom-snackbar.two-line {
    height: 80px !important;
}

.custom-snackbar.center-snackbar {
    margin-left: auto !important;
    margin-right: auto !important;
}

.snackbar-icon {
    border-radius: 8px 0 0 8px;
    display: flex;
    width: 64px;
}

.snackbar-icon.success {
    background-color: $smartester-success;
}

.snackbar-icon.warning {
    background-color: $smartester-warning;
}

.snackbar-icon.error {
    background-color: $smartester-error;
}

.snackbar-icon.info {
    background-color: $smartester-neutral-dark;
}

.line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

@import "header";
@import "login";
@import "nav";
@import "table";
@import "card";
@import "activity";
@import "profile";
@import "datepicker";
@import "modal";
