.workspace-option {
    display: flex;
    margin-left: 24px;
    margin-right: 16px;
    justify-content: space-between;
    max-width: 472px;
    height: 64px;
    border-top: 1px solid $smartester-light;
}

.workspace-option:first-child {
    border-top: unset !important;
}

.workspace-placeholder {
    width: 40px !important;
    height: 40px !important;
}

.workspace-icon {
    border-radius: 50%;
    object-fit: cover;
    width: 40px !important;
    height: 40px !important;
    border: 2px solid $smartester-light;
    background-color: #666;
}

.workspace-delete-icon {
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid $smartester-light;
    background-color: #666;
}

.workspace-delete {
    width: 56px !important;
    height: 56px !important;
    margin-top: 4px;
    margin-right: 4px;
}

.workspace-access-icon {
    position: absolute;
    top: 0;
    right: 0;
}

.workspace-image {
    position: absolute !important;
    z-index: 1 !important;
    left: 24px !important;
    top: 32px !important;
    border-radius: 50%;
    object-fit: contain;
    width: 96px !important;
    height: 96px !important;
    border: 4px solid $smartester-light;
    background-color: #666;
}

.workspace-select-btn {
    font-size: 14px !important;
    width: 76px !important;
    height: 36px !important;
    min-width: unset !important;
    min-height: unset !important;
    margin-left: 8px !important;
    border-radius: 12px !important;
    margin-top: auto !important;
    margin-bottom: auto !important;
}

.profile-card {
    max-width: 512px;
    min-width: 324px;
    width: -webkit-fill-available;
    width: -moz-available;
}

.profile-input {
    margin: 22px 8px 22px 16px !important;
    width: -webkit-fill-available;
    width: -moz-available;
}

.profile-input:first-child {
    margin-top: 40px !important;
}

.profile-input:last-child {
    margin-bottom: 20px !important;
}

.profile-input.required {
    margin-bottom: 2px !important;
}

.profile-required {
    margin: 7px 0 0 12px;
}

.profile-card-header {
    height: 160px;
    background-color: $smartester-secondary;
    margin: -8px;
    border-radius: 8px 8px 0 0;
    position: relative;
    display: grid;
}

.profile-placeholder {
    font-size: 96px !important;
    position: absolute !important;
    z-index: 1 !important;
    left: 24px !important;
    top: 32px !important;
}

.profile-image {
    position: absolute !important;
    z-index: 1 !important;
    left: 24px !important;
    top: 32px !important;
    border-radius: 50%;
    object-fit: cover;
    width: 96px !important;
    height: 96px !important;
    border: 4px solid $smartester-light;
}

.profile-edit-image {
    position: absolute !important;
    z-index: 2 !important;
    left: 24px !important;
    top: 32px !important;
    font-size: 14px !important;
    left: 88px !important;
    top: 88px !important;
    background-color: $smartester-light !important;
}

.workspaces-card {
    max-width: 512px;
}

.profile-password-change {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 40px 20px 32px 20px;
}

.profile-icon-button:hover {
    background-color: $smartester-darkest !important;    
}
