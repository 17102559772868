 .sidebar {
    position: fixed;
    min-height: 100vh !important;
    z-index: 100;
    width: 256px;
    overflow-x: hidden;
    top: 0;
    flex-direction: column;
    background-color: $smartester-secondary;
    color: $smartester-light;
    box-shadow: 0 4px 5px 1px rgba(0,0,0,.14), 0 1px 8px 2px rgba(0,0,0,.12), 0 2px 8px 0 rgba(0,0,0,.2);
    transition: width 300ms;

    .logo-box {
        margin: 18px 0;

        .logo {
            text-align: center;
            width: 200px;
            padding: 8px 12px;
            background-color: #fff;
            border-radius: 100px;
            margin: 0 28px !important;
            height: 44px;
            display: block;
            cursor: pointer;

            img {
                height: auto;
                max-width: 100%;
            }
        }
    }

    a {
        color: $smartester-light;
    }

    .nav-item {
        overflow: hidden;
        transition: height 200ms;
        font-size: 18px;
        font-family: "Montserrat-Regular";
        :hover {
            background-color: #555;
        }
        .active {
            background-color: #555;
        }
        cursor: pointer;
    }

    .nav-link {
        padding: 10px 16px 10px 32px;
        color: inherit;
    }

    .nav-hidden {
        height: 0px;
        overflow: hidden;
    }

    .sidebar-icon {
        margin-right: 16px;
        margin-bottom: 4px;
        font-size: 1.25rem;
    }
}

div[aria-labelledby="nav-manage"] {
    border: 0px;
    background: 0;
    padding: 0;
    min-width: 256px !important;
    width: 256px !important;
}

div[aria-labelledby="nav-insights"] {
    border: 0px;
    background: 0;
    padding: 0;
    min-width: 256px !important;
    width: 256px !important;
}

.nav-child {
    a {
        padding-left: 84px !important;
    }
}

.nav-closed {
    width: 0px;
    overflow-x: hidden;
}

.nav-footer {
    width: 256px;
    position: absolute;
    bottom: 0px;

    img {
        max-width: 100%;
    }
}

.nav-version-code {
    color: $smartester-neutral-dark;
    font-family: "Montserrat-Regular";
    font-size: 14px !important;
    padding: 10px 32px;
    position: absolute;
    bottom: 110px;
}
