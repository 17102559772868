.login-page {
    height: 100%;
    margin-right: 0;
    margin-left: 0;

    hr {
        border-top: 2px solid $smartester-light;
    }

    a {
        text-decoration: unset;

        &:hover {
            text-decoration: underline;
        }
    }
}

.login-panel {
    background: transparent linear-gradient(180deg, #FFFFFF 0%, #F7F7F7 100%) 0% 0% no-repeat padding-box;
    min-height: 100%;
    position: relative;
    padding-right: 0;
    padding-left: 0;
}

.login-picture {
    height: 100%;
    position: relative;
    background-size: 1152px 1080px;
    background-repeat: no-repeat;
    background-position: top right;
    background-image: url('../resources/loginSmartester.png');
    overflow: hidden;
}

@media (min-height: 1082px) {
    .login-picture {
        background-size: 1346px 1262px;
    }
}

@media (min-height: 1262px) {
    .login-picture {
        background-size: 1538px 1442px;
    }
}

@media (min-height: 1442px) {
    .login-picture {
        background-size: 1730px 1622px;
    }
}

@media (min-height: 1622px) {
    .login-picture {
        background-size: 1922px 1802px;
    }
}

@media (min-height: 1802px) {
    .login-picture {
        background-size: 2114px 1982px;
    }
}

@media (min-height: 1982px) {
    .login-picture {
        background-size: 2306px 2162px;
    }
}

.login-picture-div {
    background: transparent linear-gradient(180deg, #FFFFFF 0%, #F7F7F7 100%) 0% 0% no-repeat padding-box;
    padding-right: 0;
    padding-left: 0;
}

div[id="root"] {
    height: 100%;
}

html, body {
    height: 100%;
}

.forgot-link {
    font-size: 14px !important;
    cursor: pointer;
    color: $smartester-primary !important;
}

.criteria-check {
    text-align: left;
    vertical-align: middle;
    margin-bottom: 8px;
}

.criteria-check:last-child {
    margin-bottom: 14px;
}

.criteria-check:first-child {
    margin-top: 16px;
}

.dot {
  height: 24px;
  width: 24px;
  background-color: #FFF;
  border-radius: 50%;
  border: 1px solid $smartester-darkest;
  display: inline-block;
  vertical-align: middle;
}
.email-username-input{
    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus,
    input:-webkit-autofill::first-line,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    webkit-autofill:focus {
        -webkit-text-fill-color: black;
        -webkit-box-shadow: 0 0 0px 1000px white inset;
        font-size: 16px;
        font-family: 'Montserrat-Regular';
    }
}

.saml-or-text {
    position: relative;
    overflow: hidden;
    text-align: center;
    margin: 2.5vh 8px;
}
.saml-or-text:before, .saml-or-text:after {
    position: absolute;
    top: 51%;
    overflow: hidden;
    width: 48%;
    height: 1px;
    content: '\a0';
    background-color: $smartester-dark;
}
.saml-or-text:before {
    margin-left: -50%;
    text-align: right;
}
.saml-or-text:after {
    margin-left: 2%;
    text-align: right;
}
