$color-black: #303030;
$color-grey: #eee;
$color-dark-grey: lightgrey;
$color-white: #fff;
$color-orange: #db6a06;

$smartester-primary: #FF4351;
$smartester-secondary: #363636;
$smartester-secondary-light: #484848;
$smartester-secondary-lightest: #5A5A5A;

$smartester-support-1: #FF7185;
$smartester-support-2: #57CAFF;
$smartester-support-3: #70FF8E;
$smartester-support-4: #364EFF;
$smartester-success: #16D946;
$smartester-warning: #FFDA5C;
$smartester-error: #CC353F;

$smartester-lightest: #F2F2F2;
$smartester-light: #E0E0E0;
$smartester-neutral-light: #8D8D8D;
$smartester-neutral-dark: #757575;
$smartester-dark: #666666;
$smartester-darkest: #555555;
