$base-color: #f2f2f2;
$shine-color: #fff;
$animation-duration: 1.6s;

@mixin background-gradient {
  background-image: linear-gradient(90deg, $base-color 0px, $shine-color 40px, $base-color 80px);
  background-size: 40vw;
}

@keyframes shine-lines {
    0%, 100% {
        background-position: -10vw
    }
  
    40% {
        background-position: 12vw
    }
}

.skeleton {
    @include background-gradient;
    animation: shine-lines $animation-duration infinite linear alternate;
    border-radius: 5px;
}

.skeleton.circle {
    border-radius: 50% !important;
}