.date-popover {
    .MuiPaper-root {
        top: 240px !important;
        left: unset !important;
        right: 48px !important;
    }
}

.custom-date-picker {
    .rdrMonthPicker {
        font-family: 'Montserrat-Bold' !important;
        font-size: 14px !important;
    }

    .rdrYearPicker {
        font-family: 'Montserrat-Bold' !important;
        font-size: 14px !important;
    }

    .rdrWeekDay {
        font-family: 'Montserrat-Bold' !important;
        font-size: 14px;
        text-transform: uppercase;
        color: #666666 !important;
        margin: 0 !important;
        line-height: 24px !important;
    }

    .rdrWeekDays {
        background-color: rgba(141, 141, 141, 0.3) !important;
        border-radius: 4px !important;

    }

    .rdrDayNumber {
        font-family: 'Montserrat-Regular' !important;
        font-size: 14px !important;
    }

    .rdrDayToday .rdrDayNumber {
        border: 1px solid !important;
        border-radius: 5.042em !important;
        left: 5px !important;
        right: 5px !important;
    }

    .rdrDayToday .rdrDayNumber span:after {
        background-color: transparent !important;
    }

    .rdrDay {
        height: 44.57px !important;

    }

    .rdrNextPrevButton {
        background: #ffffff !important;
    }


    .rdrNextPrevButton i{
        border-color: transparent $smartester-darkest transparent transparent  !important;
    }

    .rdrNextButton i{
        border-color: transparent transparent transparent $smartester-darkest !important;
        
    }

    .rdrDayStartPreview {
        border: unset !important;
        border-radius: unset !important;
    }

    .rdrDayEndPreview {
        border: unset !important;
        border-radius: unset !important;
    }


    .rdrDayNumber:hover {
        border-radius: 5.042em !important;
        border-color: transparent !important;
        left: 5px !important;
        right: 5px !important;
        top: 5px !important;
        bottom: 5px !important;
        background-color: $smartester-lightest !important;
        span {
            color: black !important;
        }
    }

    .rdrDayNumber:after{
        border-color: transparent !important;
    }

    .rdrSelected {
        left: 5px !important;
        right: 5px !important;
        border-radius: 20px !important;
    }

    .MuiButton-root {
        width: 176px !important;
        font-size: 16px !important;
        font-family: "Montserrat-Regular";
        justify-content: "center";
    }

    .button-label {
        font-size: 20px !important;
        margin: 0 !important;
    }

    option {
        background-color: #ffffff !important;
        font-size: 14px !important;
        font-weight: normal !important;
        font-family: "Montserrat-Regular" !important;
        text-align: left !important;
    }
    
    .filter-button:hover {
        background-color: $smartester-lightest !important;
    }
}

.MuiPopover-paper{
    border-radius: 8px !important;
}

